.medium-font {
  font-family: "Kanit", sans-serif;
  font-weight: 500;
}

.light-font {
  font-family: "Kanit", sans-serif;
  font-weight: 300;
}

.header-content {
  display: flex;
  align-items: center;
  background-color: #404042;
  color: #ffffff;
}

.ant-layout-header {
  background-color: #404042;
  padding: 0 0.1rem;
}

.ant-menu-dark {
  background-color: #404042;
}

.ant-modal-content {
  padding: 0 !important;
}

.ant-modal-body {
  background-color: #fff;
  border-radius: 8px;
}

.ant-layout-footer {
  background-color: #1a1a1b;
}

/* .ant-layout-footer {
  background-color: #404040;
  color: #fff;
  text-align: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  padding: 2rem;
} */

.centered-container {
  display: flex;
  flex-direction: column; /* Stack children vertically */
  justify-content: center; /* Center vertically */
}

@media (max-width: 768px) {
  /* .centered-container {
    margin: 8rem 2rem;
  } */

}

.menuBar {
  padding: 0 20px;
}

.logo {
  float: left;
}

.logo {
  display: block;
  /* margin: -0.75rem auto 0; */
  margin-top: -0.75rem;
}

.logo a {
  display: inline-block;
  font-size: 20px;
  padding: 19px 20px;
  text-transform: capitalize;
}

.menuCon {
  float: left;
  /* width: calc(100% - 75px); */
  width: 75%;
}

.menuCon .ant-menu-item {
  padding: 0px 5px;
}

.menuCon .ant-menu-submenu-title {
  padding: 10px 20px;
}

.menuCon .ant-menu-item a,
.menuCon .ant-menu-submenu-title a {
  padding: 10px 15px;
}

.menuCon .ant-menu-horizontal {
  border-bottom: none;
}

.menuCon .leftMenu {
  float: left;
}

.menuCon .rightMenu {
  /* float: right; */
  margin-left: 10rem;
}

.menuCon .rightMenu .login-menu {
  display: none;
}

.barsMenu {
  float: right;
  height: 32px;
  padding: 6px;
  margin-top: 1rem;
  display: none;
  background: none;
}

.barsBtn {
  display: block;
  width: 20px;
  height: 2px;
  background: #fff;
  position: relative;
}

.barsBtn:after,
.barsBtn:before {
  content: attr(x);
  width: 20px;
  position: absolute;
  top: -6px;
  left: 0;
  height: 2px;
  background: #fff;
}

.barsBtn:after {
  top: auto;
  bottom: -6px;
}

.ant-drawer-body {
  padding: 0;
}

.barsMenu > span {
  display: block;
}

.ant-drawer-body .ant-menu-horizontal > .ant-menu-item,
.ant-drawer-body .ant-menu-horizontal > .ant-menu-submenu {
  display: inline-block;
  width: 100%;
}

.ant-drawer-body .ant-menu-horizontal {
  border-bottom: none;
}

.ant-drawer-body .ant-menu-horizontal > .ant-menu-item:hover {
  border-bottom-color: transparent;
}

@media (max-width: 767px) {
  .barsMenu {
    display: inline-block;
  }

  .leftMenu,
  .rightMenu {
    display: none;
  }

  .logo a {
    margin-left: -20px;
  }

  .menuCon .ant-menu-item,
  .menuCon .ant-menu-submenu-title {
    padding: 1px 20px;
  }

  /* .logo a {
    padding: 10px 20px;
  } */

  .menuCon .rightMenu .login-menu {
    display: block;
  }
}

.ant-drawer .ant-drawer-content {
  background-color: #1a1a1b;
}

.close-button {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  cursor: pointer;
  font-size: 1rem;
  color: #ffffffa6;
}

.ant-layout,
.ant-menu,
.ant-layout-footer,
.ant-modal-content,
.ant-dropdown-menu,
.login-menu span,
.ant-search .ant-input,
.ant-select-selection-placeholder,
.ant-card .ant-card-body {
  font-family: "Kanit", Sans-serif;
}

.ant-dropdown-menu-title-content {
  font-weight: 300;
}

.ant-modal-close-x {
  color: #fff;
}

.ant-card .ant-card-body {
  padding: 1rem;
}

.ant-card .ant-card-body p {
  margin: 0.5rem;
}

.ant-card .ant-card-meta-detail > div:not(:last-child) {
  margin-bottom: 0.1rem;
}